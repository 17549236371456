<template>
  <div class="user-profile">
    <h1>User Profile</h1>
    <p>
        This is the user profile page. I want to allow users to change their password and apply
        settings. Need to show different things based on the user role. Main tenants should be
        able to create users under their account. Should be able to set address, company name,
        etc...
    </p>

    <strong>User:</strong> {{user}}

  </div>
</template>

<script>
import state from '@/state';

export default {
  data() {
    return {
      user: state.getUser.value,
    };
  },

  methods: {
  },
};
</script>

<style scoped>

</style>
