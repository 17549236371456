<template>
  <div class="pending-tasks">
    <h1>{{ msg }}</h1>
    <p>
      Going to try and rebuild the ngsprices web app using <br/>
      Vue, Express, Postgres, NGINX, Redis and Socket.io
    </p>
    <h2>🚀 Pending Features</h2>

    <!-- <h4 style="font-weight: 400;">
      🚀 = New Feature &nbsp;&nbsp;&nbsp;
      🙌 = Enhancement &nbsp;&nbsp;&nbsp;
      🐛 = Bug Fix
    </h4> -->

    <ul>
      <li class="done">Setup Basic Express Server</li>
      <li class="done">Setup Basic Websocket Endpoint</li>
      <li class="done">Setup And Connect to Postgres DB</li>
      <li class="done">Setup "Parts" API Endpoint</li>
      <li class="done">Apply index to Postgres for faster parts query</li>
      <li class="done">Setup Basic Messaging w/ Websockets</li>
      <li class="done">Setup Basic Vue Routing</li>
      <li class="done">Setup Redis to store sessions</li>
      <li class="done">Refactor and Clean up Redis Code</li>
      <li class="done">Setup Knex for Postgres Usage</li>
      <li class="done">Setup Migrations</li>
      <li class="done">Create Users Schema</li>
      <li class="done">Setup Basic Session for logging in</li>
      <li class="done">Replace Fetch w/ Axios</li>
      <li class="done">Create Login Page</li>
      <li class="done">Setup Basic Authentication/Authorization</li>
      <li class="done">Setup Password Encryption</li>
      <li class="done">Purchase free domain for testing</li>
      <li class="done">Install Nginx Server</li>
      <li class="done">Configure SSH</li>
      <li class="done">Install Postgres</li>
      <li class="done">Install Redis Server</li>
      <li class="done">Setup Github Actions for Client deploy</li>
      <li class="done">Setup Github Actions for Server deploy</li>
      <li class="done">Install PM2</li>
      <li class="done">Setup Github Actions for NGINX deploy</li>
      <li class="done">Setup Postgres Remote connections</li>
      <li class="done">Create/Run basic Loader.io Tests</li>
      <li class="done">Update sar to log every minute</li>
      <li class="done">Setup Express/PM2 Logs</li>
      <li class="done">Setup Postgres daily backups</li>
      <li class="done">Basic Nginx log parsing w/ GoAccess</li>
      <li>Consider Docker for moving hosting providers</li>
      <li>Secure Server (UWP is currently inactive)</li>
      <li>Setup Knex Seed</li>
      <li>Much, much more...</li>
    </ul>

    <h2>🐛 Bug Fixes</h2>
    <ul>
      <li class="done">Get Websockets working with reverse proxy</li>
      <li class="done">Fix Login Redirects</li>
      <li class="done">Fix Client Action to overwrite client on run</li>
      <li class="done">Parts Search should respond 200 not 201</li>
      <li class="done">Add postgres connection to ecosystem</li>
      <li class="done">Update PM2 Environment vars</li>
      <li class="done">Fix logout flicker to homepage then login</li>
    </ul>

  </div>
</template>

<script>
export default {
  name: 'PendingTasks',
  props: {
    msg: String,
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
  cursor: default;
}
li {
  display: block;
  padding: 3px 7px;
  margin: 0 10px;
}
li.done {
  text-decoration: line-through;
  color: #abb;
}
li:hover {
  background: #f0f4ff;
  border-radius: 4px;
}
a {
  color: #4283b9;
}
</style>
