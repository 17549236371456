<template>
  <div id="nav" class="sidebar">
    <div class="user">
      <h3 v-if="fullname">Hi {{fullname}}</h3>
      <div class="user-count" v-if="userCount">
        <span>{{userCount}}</span>
        user(s) online
      </div>
    </div>

    <br>
    <router-link to="/"><SvgIcons icon="home" /><span>Home</span></router-link>
    <router-link to="/profile"><SvgIcons icon="person" /><span>Profile</span></router-link>
    <router-link to="/parts"><SvgIcons icon="rocket" /><span>Parts</span></router-link>
    <router-link to="/sandbox"><SvgIcons icon="location" /><span>Sandbox</span></router-link>
    <router-link to="/contacts"><SvgIcons icon="people" /><span>Contacts</span></router-link>
    <router-link to="/auth"><SvgIcons icon="lock" /><span>Auth</span></router-link>
    <router-link to="/logout"><SvgIcons icon="logoff" /><span>Logout</span></router-link>
  </div>
</template>

<script>
import state from '../state';
import SvgIcons from '@/components/SvgIcons.vue';

export default {
  name: 'Sidebar',
  components: {
    SvgIcons,
  },

  data() {
    return {
      user: state.getUser,
      fullname: state.getUserFullName,
      userCount: state.getUserCount,
    };
  },
};
</script>

<style scoped>
.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 275px;
    background: white;
    box-shadow: -5px 0 40px rgba(0, 32, 64, .15);
}

.sidebar a {
    display: block;
    margin: 10px 20px;
    padding: 12px 24px;
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
}
.sidebar a > * {
    vertical-align: middle;
    display: inline-block;
}
.sidebar a svg {
    height: 20px;
    width: 20px;
    margin-right: 15px;
}

.sidebar a.router-link-exact-active {
    background-color: #4c6fff;
    color: #fff;
    border-radius: 5px;
}
.sidebar a.router-link-exact-active svg {
    color: #fff;
}

.sidebar .user {
  margin: 10px 20px;
  padding: 12px 24px;
}
</style>
