import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import axios from './axios';
import state from './state';

async function init() {
  try {
    const { status, data } = await axios.get('/auth/check-session');
    if (status === 200) state.setUser(data);
  } catch (err) {
    console.error(err);
  }

  createApp(App).use(store).use(router).mount('#app');
}

init();
