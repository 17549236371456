import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Parts from '@/views/Parts.vue';
import Sandbox from '@/views/Sandbox.vue';
import Auth from '@/views/Auth.vue';
import Login from '@/views/Auth/Login.vue';
import UserProfile from '@/views/UserProfile/UserProfile.vue';
import Contacts from '@/views/Contacts.vue';
import state from '../state';
import axios from '../axios';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home',
      authorize: [],
    },
  },
  {
    path: '/parts',
    name: 'Parts',
    component: Parts,
    meta: {
      title: 'Parts',
      authorize: [],
    },
  },
  {
    path: '/sandbox',
    name: 'Sandbox',
    component: Sandbox,
    meta: {
      title: 'Sandbox',
      authorize: [],
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: UserProfile,
    meta: {
      title: 'Profile',
      authorize: [],
    },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      title: 'Contacts',
      authorize: [],
    },
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    meta: {
      title: 'Sandbox',
      authorize: ['admin'],
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      // If already logged in, redirect user to home page
      if (state.getUser.value) return next({ path: '/' });
      return next();
    },
  },

  // Route Redirects
  {
    path: '/logout',
    redirect: () => {
      axios.delete('/auth/logout');
      state.setUser(null);
      return '/login';
    },
  },

  // Routes Fallback/NotFound
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { authorize, title } = to.meta;
  const user = state.getUser.value;

  // Set browser tab title
  document.title = `NGS Prices ${title ? `- ${title}` : ''}`;

  // Basic Authorization Check
  if (authorize) {
    if (!user) {
      return next({ path: '/login', query: { returnUrl: to.path } });
    }

    if (authorize.length && (!user.roles || !authorize.some((r) => user.roles.includes(r)))) {
      alert('You Shall NOT Pass! (403 Forbidden)');
      if (from.path === '/') return next({ path: '/' });
      return console.error('You Shall NOT Pass! (403 Forbidden)');
    }
  }

  return next();
});

export default router;
