<template>
  <Sidebar v-if="user" />

  <div class="page-body">
    <router-view />
  </div>

</template>

<script>
import { io } from 'socket.io-client';
import state from './state';

import Sidebar from '@/components/Sidebar.vue';

const socketUrl = process.env.NODE_ENV === 'development'
  ? 'http://localhost:3000'
  : 'https://ngsprices.ml';

export default {
  components: {
    Sidebar,
  },

  data() {
    return {
      socket: null,
      user: state.getUser,
      userCount: state.getUserCount,
    };
  },

  watch: {
    user: {
      immediate: true,
      handler(value) {
        console.log('updated user value');
        if (value) {
          this.socket = io(socketUrl);
          this.socket.on('server/userCount', (count) => {
            state.setUserCount(count);
            console.log('updated userCount', count);
          });
        } else if (this.socket) {
          this.socket.disconnect();
          state.setUserCount(null);
        }
      },
    },
  },

};
</script>

<style>
body {
  background: #f8f9fd;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 800px;
  margin: auto;
  box-sizing: border-box;
}

h3 {
  margin: 0;
}

.page-body {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  overflow-y: auto;
}
.sidebar ~ .page-body {
  left: 275px;
}
.page-body > * {
  width: 1000px;
  margin: 0 auto;
  padding: 0 50px;
}

</style>
