<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <PendingTasks msg="Welcome to NGS Prices"/>
  </div>
</template>

<script>
// @ is an alias to /src
import PendingTasks from '@/components/PendingTasks.vue';

export default {
  name: 'Home',
  components: {
    PendingTasks,
  },
};
</script>
