// https://vueschool.io/articles/vuejs-tutorials/state-management-with-composition-api/
import { reactive, computed } from 'vue';

const state = reactive({
  user: null,
  userCount: null,
});

const getUser = computed(() => state.user);
const getUserFullName = computed(() => (state.user ? state.user.email : ''));
const getUserCount = computed(() => state.userCount);

function setUser(user) {
  state.user = user;
}

function setUserCount(count) {
  state.userCount = count;
}

const globalState = {
  getUser,
  getUserFullName,
  getUserCount,
  setUser,
  setUserCount,
};

export default globalState;
