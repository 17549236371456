<template>
  <div class="parts">
    <h1>This is the parts page</h1>

    <form @submit.prevent="onSubmit">
      <input v-model="search" ref="search" placeholder="Search..." />
      <button>Send</button>
    </form>

    <div class="part">
      <div class="error" v-bind:class="{ visible: (typeof part === 'string')}">
        <strong>{{(typeof part === 'string') ? part : ''}}</strong> not found
      </div>

      <div class="prop">
        <span class="title">Number</span>
        <span class="value">{{part.number || '-'}}</span>
      </div>

      <div class="prop">
        <span class="title">Description</span>
        <span class="value">{{part.description || '-'}}</span>
      </div>

      <div class="prop">
        <span class="title">Price</span>
        <span class="value">{{formatCurrency(part.price, '-')}}</span>
      </div>

      <div class="prop">
        <span class="title">Weight</span>
        <span class="value">{{formatWeight(part.weight, '-')}}</span>
      </div>

      <div class="prop">
        <span class="title">Core</span>
        <span class="value">{{formatCurrency(part.core, '-')}}</span>
      </div>

      <div class="prop">
        <span class="title">Updated</span>
        <span class="value">{{formatDate(part.updated, '-')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  data() {
    return {
      search: '',
      part: {},
    };
  },

  methods: {
    formatDate(value, emptyValue = '') {
      if (!value) return emptyValue;
      return new Date(value).toLocaleDateString();
    },
    formatCurrency(value, emptyValue = '') {
      if (!value) return emptyValue;
      return `$${value}`;
    },
    formatWeight(value, emptyValue = '') {
      if (!value) return emptyValue;
      return `${value} lbs`;
    },

    async onSubmit() {
      if (this.search.length < 1) return;

      try {
        const parts = (await axios.get(`parts/number/${this.search}`)).data;
        this.part = (Array.isArray(parts) && parts.length > 0) ? parts[0] : `${this.search}`;
      } catch (err) {
        console.error('Error', err);
      } finally {
        this.$refs.search.select();
      }
    },
  },
};
</script>

<style scoped>
  .part {
      margin-top: 5px;
  }
  .part .prop {
      margin-bottom: 5px;
  }
  .part .prop .title {
      display: inline-block;
      width: 100px;
      padding: 5px 10px;
      background-color: #444;
      color: #fff;
  }
  .part .prop .value {
      display: inline-block;
      width: 250px;
      padding: 5px 10px;
      background: #f0f4ff;
  }
  .part .error {
    color: red;
    visibility: hidden;
  }
  .part .error.visible {
    visibility: visible;
  }
</style>
