<template>
  <div class="login">
    <form v-if="showLogin" @submit.prevent="login">
        <h1>Sign In</h1>

        <label for="">Email</label>
        <input v-model="email" ref="email" placeholder="@mail.com" />
        <br>

        <label for="">Password</label>
        <input v-model="password" placeholder="Password" />
        <br>

        <button>Sign In</button>
    </form>

    <form v-else @submit.prevent="signup">
      <input v-model="email" ref="email" placeholder="Email..." />
      <input v-model="password" placeholder="Password..." />
      <button>Sign Up</button>
    </form>

    <br>

    <div class="response">{{response}}</div>

    <!-- <div>
      <button @click="handleToggle">
        {{ this.showLogin ? 'Sign Up' : 'Login'}}
      </button>
    </div> -->
  </div>
</template>

<script>
import state from '../../state';
import axios from '../../axios';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      response: '',
      showLogin: true,
      user: state.getUser,
    };
  },

  methods: {
    handleToggle() {
      this.showLogin = !this.showLogin;
    },

    async login() {
      if (this.email.length < 1 || this.password.length < 1) return;

      try {
        const formData = { email: this.email, password: this.password };
        this.response = await axios.post('/auth/login', formData);
        console.log('response:', this.response.data);
        state.setUser(this.response.data);
        this.$router.push(this.$route.query.returnUrl || '/');
      } catch (err) {
        this.response = err;
        console.error('Error', err);
      } finally {
        this.$refs.email.select();
      }
    },

    async signup() {
      if (this.email.length < 1 || this.password.length < 1) return;

      try {
        const formData = { email: this.email, password: this.password };
        await axios.post('/auth/sign-up', formData);
        this.response = 'Account created! Login to start using the app.';
        this.showLogin = true;
      } catch (err) {
        this.response = err;
        console.error('Error', err);
      } finally {
        this.$refs.email.select();
      }
    },
  },

};
</script>

<style scoped>
.login {
  width: 375px;
  padding: 50px;
  background: white;
  margin: 50px auto;
  line-height: 1.6;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 64, 127, 0.15);
}
.login * {
  box-sizing: border-box;
}

.login h1 {
    margin-top: 0;
}

label {
  display: block;
  margin-top: 10px;
  font-weight: 600;
  text-align: left;
}
input {
    height: 45px;
    width: 100%;
    padding: 0 10px;
    outline: 0;
    border: 0;
    border-radius: 5px;
    background: #f3f7fa;
    color: #444;
}
input::placeholder {
    color: #bfc7d0;
}
button {
    margin-top: 30px;
    padding: 5px 15px;
    height: 45px;
    width: 100%;
    background-color: #4c6fff;
    color: #fff;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}
button:hover {
    background-color: #3c5fef;
}
</style>
