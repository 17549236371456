<template>
  <div class="auth">
    <h1>This is the auth page</h1>

    <form v-if="toggle" @submit.prevent="login">
      <input v-model="email" ref="email" placeholder="Email..." />
      <input v-model="password" ref="password" placeholder="Password..." />
      <button>Login</button>
    </form>

    <form  v-else @submit.prevent="signUp">
      <input v-model="email" ref="email" placeholder="Email..." />
      <input v-model="password" ref="password" placeholder="Password..." />
      <button>Sign Up</button>
    </form>

    <div class="response">{{response}}</div>

    <br>
    <div>--- OR ---</div>
    <br>

    <div>
      <button @click="handleToggle">
        {{ this.toggle ? 'Sign Up' : 'Login'}}
      </button>
    </div>

    <br>
    <div>
      <button @click="testAuth">Test Authentication</button>
      <div class="auth-result">{{authMessage}}</div>
    </div>

  </div>
</template>

<script>
import axios from '../axios';

export default {
  data() {
    return {
      toggle: true,
      email: '',
      password: '',
      response: '',
      authMessage: '',
    };
  },

  methods: {
    handleToggle() {
      this.toggle = !this.toggle;
    },

    async testAuth() {
      try {
        this.authMessage = await axios.get('/auth/testAuth');
      } catch (err) {
        this.authMessage = err;
      }
    },

    async login() {
      if (this.email.length < 1 || this.password.length < 1) return;

      try {
        this.response = await axios.post('/auth/login', { email: this.email, password: this.password });
      } catch (err) {
        this.response = err;
        console.error('Error', err);
      } finally {
        this.$refs.email.select();
      }
    },

    async signUp() {
      if (this.email.length < 1 || this.password.length < 1) return;

      try {
        this.response = await axios.post('/auth/sign-up', { email: this.email, password: this.password });
      } catch (err) {
        this.response = err;
      } finally {
        this.$refs.email.select();
      }
    },

  },
};
</script>
