<template>
  <div class="contacts">
    <h1>Contacts</h1>
  </div>
</template>

<script>
</script>

<style scoped>
</style>
