import axios from 'axios';

const axiosDefaults = {
  baseURL: '/api',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
};

if (process.env.NODE_ENV === 'development') {
  axiosDefaults.baseURL = 'http://localhost:3000/api';
}

export default axios.create(axiosDefaults);
