<template>
  <div class="sandbox">
    <h1>This is the sandbox page</h1>

    <form @submit.prevent="onSubmit">
      <input v-model="input" ref="input" placeholder="Message..." minlength="1" required/>
      <button>Send</button>
    </form>

    <div class="messages">
      <div v-for="message in messages" v-bind:key="message" class="message">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: '',
      messages: [],
    };
  },
  created() {
    this.$root.socket.on('messages/receive', (message) => this.messages.push(`RECEIVED - ${message}`));
    this.$root.socket.on('messages/broadcast', (message) => this.messages.push(`BROADCAST - ${message}`));
  },
  methods: {
    async onSubmit() {
      console.log('posting message:', this.input);
      if (this.input.length < 1) return;
      this.$root.socket.emit('messages/send', this.input);

      // Clear and select input
      this.input = '';
      this.$refs.input.select();
    },
  },
};
</script>
